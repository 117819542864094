import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Game from "./components/Pages/Game";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./components/Pages/Home";
import Document from "./components/Pages/Document";
import DocumentDetail from "./components/Document/DocumentDetail.js";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        {/* Rotta Pubblica per Home */}
        <Route path="/home" element={<Home />} />
        <Route path="/documents/*" element={<Document />} />
        <Route path="/documents/:slug" element={<DocumentDetail />} />

        {/* Rotta per Recovery Password */}
        <Route path="/recovery-password/:token" element={<Home externalView="recoverypassword"/>} />

        {/* Rotta Privata per /game */}
        <Route path="/game/*" element={<PrivateRoute component={Game} />} />

        {/* Rotte Predefinite */}
        <Route path="/" element={<Navigate to="/home" />} />
      </Routes>
    </Router>
  );
}

export default App;
