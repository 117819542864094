import api from "./api";

export const login = async (username, password) => {
  const response = await api.post("/auth/login", { username, password });
  return response.data;
};

export const register = async (username, email, password) => {
  const response = await api.post("/auth/register", {
    username,
    email,
    password,
  });
  return response.data;
};

export const logout = async () => {
  await api.post("/auth/logout");
};

export const getUser = async () => {
  const response = await api.get("/auth/user");
  return response.data;
};

export const forgotPassword = async (username) => {
  const response = await api.post("/auth/forgot-password", { username });
  return response.data;
};

export const resetPassword = async (token, password) => {
  const response = await api.post("/auth/reset-password", { token, password });
  return response.data;
};

export const verifyToken = async () => {
  try {
    const response = await api.get("/auth/verify-token");
    return response.data;
  } catch (error) {
    return null;
  }
};
