import io from 'socket.io-client';
import { generateSessionId } from '../utils/generateSessionId';

let socket;
let listenerInstance = new Set()

const logIncomingMessages = (socket) => {
  const originalOn = socket.on;
  socket.on = (event, callback) => {
    const wrappedCallback = (...args) => {
      console.log(`Incoming message for event ${event}:`, ...args);
      if (callback) {
        callback(...args);
      }
    };
    originalOn.call(socket, event, wrappedCallback);
  };
};

export const connectSocket = (characterId) => {
  if (!socket && characterId) {
    const sessionId = generateSessionId();
    console.log('*************** Connecting to socket server', sessionId, characterId);
    socket = io('https://server.tenpennynovels.com', {
      query: {
        sessionId,
        characterId
      }
    });

    logIncomingMessages(socket);

    socket.on('connect', () => {
      console.log('*************** Connected to socket server');
    });

    socket.on('disconnect', () => {
      console.log('*************** Disconnected from socket server');
    });

    socket.on('heartbeat', () => {
      socket.emit('heartbeat_ack');
    });
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

// Eventi in uscita
export const leaveRoom = (roomId) => {
  if (socket) {
    console.log('Leaving room', roomId);
    socket.emit('leaveRoom', roomId);
  }
}; 
export const notifyLogout = (userId) => {
  if (socket) {
    console.log('Notifying logout', userId);
    socket.emit('userLogout', userId);
  }
};

// Eventi in entrata
export const onChatMessage = (instanceId, callback) => {
  if (socket && !listenerInstance.has(instanceId)) {
    console.warn('Listening for messages');
    socket.on('chatMessage', callback);
    listenerInstance.add(instanceId);
  }
};

export const offChatMessage = (instanceId) => {
  if (socket && listenerInstance.has(instanceId)) {
    console.warn('Stopped listening for messages');
    socket.off('chatMessage');
    listenerInstance.delete(instanceId);
  }
};  

export const onCharacterOnlineMessage = (instanceId, callback) => {
  if (socket && !listenerInstance.has(instanceId)) {
    console.warn('Listening for online characters');
    socket.on('characterOnline', callback);
    listenerInstance.add(instanceId);
  }
};

export const offCharacterOnlineMessage = (instanceId) => {
  if (socket && listenerInstance.has(instanceId)) {
    console.warn('Stopped listening for online characters');
    socket.off('characterOnline');
    listenerInstance.delete(instanceId);
  }
};

export const onFlashNewsMessage = (instanceId, callback) => {
  if (socket && !listenerInstance.has(instanceId)) {
    console.warn('Listening for flash news');
    socket.on("flashNews", callback);
    listenerInstance.add(instanceId);
  }
};

export const offFlashNewsMessage = (instanceId) => {
  if (socket && listenerInstance.has(instanceId)) {
    console.warn('Stopped listening for flash news');
    socket.off("flashNews");
    listenerInstance.delete(instanceId);
  }
};

export const onWeatherInfoMessage = (instanceId, callback) => {
  if (socket && !listenerInstance.has(instanceId)) {
    console.warn('Listening for weather info');
    socket.on("weatherInfo", callback);
    listenerInstance.add(instanceId);
  }
};

export const offWeatherInfoMessage = (instanceId) => {
  if (socket && listenerInstance.has(instanceId)) {
    console.warn('Stopped listening for weather info');
    socket.off("weatherInfo");
    listenerInstance.delete(instanceId);
  }
}; 