import React, { useContext } from "react";
import OnlineUsers from "../Character/OnlineCharacters";
import ChatList from "../Chat/ChatList";
import CharacterDetail from "../Character/CharacterDetail";
import ChatRoom from "../Chat/ChatRoom";
import { AuthContext } from "../../context/AuthContext";
import { GameContext } from "../../context/GameContext";
import { logout as logoutService } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import "./Game.css";
import FlashNews from "../FlashNews/FlashNews";
import Utility from "../Utility/Utility"; // Importa il nuovo componente Utility
import Market from "../Market/Market"; // Importa il componente Market
import MessageInbox from "../Messaging/MessageInbox";
import Weather from "../Weather/Weather";

const Game = () => {
  const { activeSection, setActiveSection, haveNewChatMessage } =
    useContext(GameContext);
  const { character, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logoutService();
    logout();
  };

  const renderComponent = () => {
    switch (activeSection.view) {
      case "character":
        return <CharacterDetail characterId={activeSection.characterId} />;
      case "mapList":
        return <ChatList />;
      case "chatRoom":
        return <ChatRoom roomId={activeSection.roomId} />;
      case "messages":
        return <MessageInbox />;
      case "utility":
        return <Utility section={activeSection.utility} />;
      case "market":
        return <Market />;
      default:
        return <ChatList />;
    }
  };

  const handleGotoDocumenti = () => {
    navigate("/documents");
  };

  return (
    <div className="mainpage_wrapper">
      <div className="mainpage_sidebar">
        <div className="sidebar_section section_weather">
          <Weather />
        </div>
        <div className="sidebar_section section_onlineusers">
          <OnlineUsers />
        </div>
        <div className="sidebar_section section_flashnews">
          <FlashNews />
        </div>
      </div>
      <div className="mainpage_content">
        <div className="mainpage_header">
          <div>
            <button
              onClick={() =>
                setActiveSection({
                  view: "character",
                  characterId: character.id,
                })
              }
            >
              Scheda del Personaggio
            </button>
            <button onClick={() => setActiveSection({ view: "mapList" })}>
              Lista Chats {haveNewChatMessage && <span>!</span>}
            </button>
            <button onClick={() => setActiveSection({ view: "messages" })}>
              Messaggi
            </button>
            <button onClick={() => setActiveSection({ view: "market" })}>
              Mercato
            </button>
            <div className="dropdown">
              <button className="dropdown-toggle">Utility</button>
              <div className="dropdown-menu">
                <button
                  onClick={() =>
                    setActiveSection({ view: "utility", utility: "banca" })
                  }
                >
                  Banca
                </button>
                <button
                  onClick={() =>
                    setActiveSection({
                      view: "utility",
                      utility: "professioni",
                    })
                  }
                >
                  Professioni / Annunci / Albo
                </button>
                <button
                  onClick={() =>
                    setActiveSection({ view: "utility", utility: "abitazioni" })
                  }
                >
                  Abitazioni
                </button>
                <button
                  onClick={() =>
                    setActiveSection({ view: "utility", utility: "staff" })
                  }
                >
                  Staff
                </button>
                <button
                  onClick={() =>
                    setActiveSection({ view: "utility", utility: "anagrafe" })
                  }
                >
                  Anagrafe / Prestavolti
                </button>
              </div>
            </div>
            <button onClick={handleGotoDocumenti}>Documenti</button>
            <button onClick={handleLogout}>Logout</button>
          </div>
        </div>
        <div className="mainpage_body">{renderComponent()}</div>
      </div>
    </div>
  );
};

export default Game;
