import React, { createContext, useState, useEffect, useContext } from "react";
import { getChatRoomVersion, getChatRooms } from "../services/chatService";
import { getDocumentVersion, getDocuments } from "../services/documentService";
import {
  offCharacterOnlineMessage,
  offFlashNewsMessage,
  offWeatherInfoMessage,
  onCharacterOnlineMessage,
  onFlashNewsMessage,
  onWeatherInfoMessage,
} from "../services/socketService";
import { AuthContext } from "./AuthContext";
import { getCharacterOnline } from "../services/characterService";
import { getFlashNews } from "../services/flashNews";
import {
  saveDBDocuments,
  getDBDocuments,
  saveDBDocumentVersion,
  getDBDocumentVersion,
  saveDBChatRooms,
  getDBChatRooms,
  saveDBChatRoomVersion,
  getDBChatRoomVersion,
} from "../services/idbService";
import { getWeatherInfo } from "../services/weatherInfo";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [chatList, setChatList] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [characterOnline, setCharacterOnline] = useState(null);
  const [flashNews, setFlashNews] = useState([]);
  const [weather, setWeather] = useState({});
  const { socketLive } = useContext(AuthContext);

  const getTreeObject = async (chatRooms) => {
    try {
      // Mappa per tenere traccia delle chat rooms
      const chatRoomMap = {};
      chatRooms.forEach((room) => {
        chatRoomMap[room._id] = { ...room, children: [] };
      });

      // Costruisci la struttura gerarchica
      chatRooms.forEach((room) => {
        if (room.children && room.children.length > 0) {
          const newChildren = [];
          room.children.forEach((childId) => {
            if (chatRoomMap[childId]) {
              newChildren.push(chatRoomMap[childId]);
            }
          });
          chatRoomMap[room._id].children = newChildren;
        }
      });

      // Trova le root chat rooms (che non sono children di nessuna altra chat room)
      const rootChatRooms = chatRooms
        .filter((room) => {
          return !chatRooms.some((r) => r.children.includes(room._id));
        })
        .map((room) => chatRoomMap[room._id]);

      console.warn(rootChatRooms);
      return rootChatRooms;
    } catch (error) {
      console.error(
        "Errore durante la costruzione della struttura gerarchica delle chat rooms:",
        error
      );
      throw error;
    }
  };

  const refreshDocuments = async () => {
    const docs = await getDocuments();
    setDocuments(docs);
    await saveDBDocuments(docs);
  };

  const refreshCharacterOnline = async () => {
    const docs = await getCharacterOnline();
    setCharacterOnline(docs);
  };

  const refreshFlashNews = async () => {
    const docs = await getFlashNews();
    setFlashNews(docs);
  };

  const refreshWeatherInfo = async () => {
    const docs = await getWeatherInfo();
    setWeather(docs);
  };

  useEffect(() => {
    const checkAndUpdateChatRooms = async () => {
      const remoteVersion = await getChatRoomVersion();
      const localVersion = await getDBChatRoomVersion();
      if (
        !localVersion ||
        (remoteVersion && remoteVersion.version !== localVersion)
      ) {
        console.warn("refreshing chat list", remoteVersion);
        const maps = await getChatRooms();
        const treeMaps = await getTreeObject(maps);
        await saveDBChatRooms(maps);
        await saveDBChatRoomVersion(remoteVersion?.version);
        setChatList(treeMaps);
      } else {
        const cachedChats = await getDBChatRooms();
        const treeMaps = await getTreeObject(cachedChats);
        setChatList(treeMaps);
      }
    };

    const checkAndUpdateDocuments = async () => {
      const remoteVersion = await getDocumentVersion();
      console.log("remoteVersion", remoteVersion);
      const localVersion = await getDBDocumentVersion();
      console.log("localVersion", localVersion);
      if (
        !localVersion ||
        (remoteVersion && remoteVersion.version !== localVersion)
      ) {
        console.warn("refreshing documents", remoteVersion);
        await refreshDocuments();
        await saveDBDocumentVersion(remoteVersion?.version);
      } else {
        const cachedDocs = await getDBDocuments();
        setDocuments(cachedDocs);
      }
    };

    const getCachedChatList = async () => {
      if (chatList === null) {
        await checkAndUpdateChatRooms();
      }
    };

    const getCachedDocuments = async () => {
      if (documents === null) {
        await checkAndUpdateDocuments();
      }
    };

    const getCachedCharacterOnline = async () => {
      if (characterOnline === null) {
        await refreshCharacterOnline();
      }
    };

    const getCachedFlashNews = async () => {
      if (flashNews === null) {
        await refreshFlashNews();
      }
    };

    const getCachedWeatherInfo = async () => {
      if (weather === null) {
        await refreshWeatherInfo();
      }
    };

    getCachedDocuments();

    if (socketLive) {
      getCachedChatList();
      getCachedCharacterOnline();
      getCachedFlashNews();
      getCachedWeatherInfo();

        onCharacterOnlineMessage('DataContext_onCharacterOnlineMessage',(characters) => {
          console.log("received character online message", characters);
          setCharacterOnline(characters);
        });

        onFlashNewsMessage('DataContext_onFlashNewsMessage', (news) => {
          console.log("received flash news message", news);
          setFlashNews(news);
        });

        onWeatherInfoMessage('DataContext_onWeatherInfoMessage',(weatherInfo) => {
          console.log("received weather info", weatherInfo);
          setWeather(weatherInfo);
        });
    } else {
        offCharacterOnlineMessage('DataContext_onCharacterOnlineMessage');
        offFlashNewsMessage('DataContext_onFlashNewsMessage');
        offWeatherInfoMessage('DataContext_onWeatherInfoMessage');
    }
  }, [socketLive, chatList, documents, characterOnline, flashNews, weather]);

  return (
    <DataContext.Provider
      value={{
        chatList,
        documents,
        characterOnline,
        flashNews,
        weather,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
