import React from 'react';

const Utility = ({section}) => {
  return (
    <div>
        <div>Utility</div>
        <div>{section}</div>
    </div>
  );
};

export default Utility;
