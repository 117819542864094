import React, { useContext, useEffect, useState } from "react";
import { GameContext } from "../../context/GameContext";
import { DataContext } from "../../context/DataContext";
import "./OnlineCharacters.css";

const OnlineCharacters = () => {
  const { characterOnline } = useContext(DataContext);
  const [localCharacterOnline, setLocalCharacterOnline] = useState(
    characterOnline || []
  );
  const { activeMap, setActiveSection, setActiveMap, getRoomInfo } =
    useContext(GameContext);

  useEffect(() => {
    setLocalCharacterOnline(characterOnline || []);
  }, [characterOnline]);

  const charactersInCurrentRoom = activeMap
    ? localCharacterOnline.filter((character) => character.room === activeMap)
    : localCharacterOnline;

  const handleCharacterClick = (characterId) => {
    setActiveSection({
      view: "character",
      characterId: characterId,
    });
  };

  const handleRoomClick = (roomId) => {
    setActiveMap(roomId);
    setActiveSection({ view: "chatRoom", roomId });
  };

  const getRoomInfoName = (roomId) => {
    const roomInfo = getRoomInfo(roomId);
    return roomInfo ? (
      <div
        className="onlinecharacters_link"
        onClick={() => handleRoomClick(roomId)}
      >
        {roomInfo.name}
      </div>
    ) : (
      "Main Page"
    );
  };

  return (
    <div className="onlinecharacters_wrapper">
      <h2>{activeMap ? getRoomInfoName(activeMap) : "Personaggi online"}</h2>
      <div className="onlinecharacters">
        {charactersInCurrentRoom?.map((c) => (
          <div className="onlinecharacters_item" key={c._id}>
            <div onClick={() => handleCharacterClick(c._id)}>
              {c.name || "missing name"}
            </div>
            {!activeMap && (
              <div
                className="character_room"
                onClick={() => handleRoomClick(c.room)}
              >
                Stanza: {getRoomInfoName(c.room)}
              </div>
            )}
            <div className="debug">{c._id}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OnlineCharacters;
