import React, { useContext, useState } from "react";
import { DataContext } from "../../context/DataContext";

const DocumentList = () => {
  const [document, setDocument] = useState(null);
  const { documents } = useContext(DataContext);

  const handleGotoDetail = (docId) => {
    setDocument(documents.find((doc) => doc._id === docId));
  };

  return (
    <div className="documentlist_wrapper">
      <div className="documentlist_header">
        <h2>Documents</h2>
      </div>
      <div className="documentlist_sidebar">
        <table>
          {documents?.map((doc) => (
            <tr key={doc._id}>
              <td>
                <span>{doc.title}</span>
              </td>
              <td>
                <a href={`/documents/${doc.slug}`}>Redirect</a>
              </td>
              <td>
                <button onClick={() => handleGotoDetail(doc._id)}>
                  Navigate
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
      <div className="documentlist_content">
        {document ? (
          <div>
            <h3>{document.title}</h3>
            <p>{document.content}</p>
            <div>{JSON.stringify(document,null,4)}</div>
          </div>
        ) : (
          <div>Select a document</div>
        )}
      </div>
    </div>
  );
};

export default DocumentList;
