import { openDB } from 'idb';

const DB_NAME = 'TenPennyNovelsDB';
const DOC_STORE = 'documents';
const CHAT_STORE = 'chatRooms';
const VERSION_STORE = 'versionStore';

const initDB = async () => {
  const db = await openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(DOC_STORE)) {
        db.createObjectStore(DOC_STORE, { keyPath: '_id' });
      } 
      if (!db.objectStoreNames.contains(CHAT_STORE)) {
        db.createObjectStore(CHAT_STORE, { keyPath: '_id' });
      }
      if (!db.objectStoreNames.contains(VERSION_STORE)) {
        db.createObjectStore(VERSION_STORE, { keyPath: 'key' });
      }
    },
  });
  return db;
};

// Document functions
export const saveDBDocuments = async (documents) => {
  const db = await initDB();
  const tx = db.transaction(DOC_STORE, 'readwrite');
  documents.forEach((doc) => tx.store.put(doc));
  await tx.done;
};

export const getDBDocuments = async () => {
  const db = await initDB();
  return await db.getAll(DOC_STORE);
};

export const saveDBDocumentVersion = async (version) => {
  const db = await initDB();
  await db.put(VERSION_STORE, { key: 'documents', version });
};

export const getDBDocumentVersion = async () => {
  const db = await initDB();
  const versionEntry = await db.get(VERSION_STORE, 'documents');
  return versionEntry ? versionEntry.version : null;
};

// Chat Room functions
export const saveDBChatRooms = async (chatRooms) => {
  const db = await initDB();
  const tx = db.transaction(CHAT_STORE, 'readwrite');
  chatRooms.forEach((room) => tx.store.put(room));
  await tx.done;
};

export const getDBChatRooms = async () => {
  const db = await initDB();
  return await db.getAll(CHAT_STORE);
};

export const saveDBChatRoomVersion = async (version) => {
  const db = await initDB();
  await db.put(VERSION_STORE, { key: 'chatRooms', version });
};

export const getDBChatRoomVersion = async () => {
  const db = await initDB();
  const versionEntry = await db.get(VERSION_STORE, 'chatRooms');
  return versionEntry ? versionEntry.version : null;
};

// Common function to get versions
export const getDBVersions = async () => {
  const db = await initDB();
  return await db.getAll(VERSION_STORE);
};
