import React, { useState } from "react";
import { resetPassword } from "../../services/authService";
import { useParams } from "react-router-dom";

const RecoveryPassword = ({ onSubmit}) => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }

    try {
      await resetPassword(token, password);
      onSubmit("Password reset successfully");
      setMessage("");
    } catch (err) {
      setMessage("Error resetting password");
    }
  };

  return (
    <div className="recoverypassword_wrapper">
      <form onSubmit={handleSubmit}>
        <div>
          <label>New Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Confirm Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        {message && <p>{message}</p>}
        <button type="submit">Reset Password</button>
      </form>
    </div>
  );
};

export default RecoveryPassword;
