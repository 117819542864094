import React, { useContext, useEffect, useState } from "react";
import { GameContext } from "../../context/GameContext";
import { DataContext } from "../../context/DataContext";
import { joinChatRoom } from "../../services/chatService";
import "./ChatList.css";

const ChatList = () => {
  const { setActiveMap, setActiveSection } = useContext(GameContext);
  const { chatList } = useContext(DataContext);
  const [localChatList, setLocalChatList] = useState([]);

  useEffect(() => {
    if (chatList) {
      setLocalChatList(chatList);
    }
  }, [chatList]);

  const handleRoomClick = async (roomId) => {
    console.log("Setting active map:", roomId);
    try {
      await joinChatRoom(roomId);
      setActiveMap(roomId);
      setActiveSection({ view: "chatRoom", roomId });
    } catch (error) {
      alert("Accesso alla stanza negato");
    }
  };

  const gotoMainPage = () => {
    setActiveMap(null);
  };

  const renderChatLinks = (rooms) => {
    return rooms.map((room) => (
      <div key={room._id} className="chatlist_groupitem">
        <div
          className="chatlist_item"
          onClick={() => handleRoomClick(room._id)}
        >
          {room.name} ({room._id})
        </div>
        {room.children.length > 0 && (
          <div className="chatlist_children">
            {renderChatLinks(room.children)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="chatlist_wrapper">
      <h2>Mappe</h2>
      <button onClick={() => gotoMainPage()}>Torna a Main</button>
      <div className="chatlist">{renderChatLinks(localChatList)}</div>
    </div>
  );
};

export default ChatList;
