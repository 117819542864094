import React, { useEffect, useContext, useState } from "react";
import ChatMessage from "./ChatMessage";
import { leaveRoom } from "../../services/socketService";
import { AuthContext } from "../../context/AuthContext";
import { GameContext } from "../../context/GameContext";
import "./ChatRoom.css";

const ChatRoom = ({ roomId }) => {
  const [actualRoomId, setActualRoomId] = useState(roomId);
  const { character } = useContext(AuthContext);
  const {
    getRoomInfo,
    roomMessages,
    refreshChatMessages,
    haveNewChatMessage,
    setHaveNewChatMessage,
  } = useContext(GameContext);

  useEffect(() => {
    if (character && actualRoomId !== roomId) {
      console.log("CHATROOM Connecting socket", character.id, roomId);
      setActualRoomId(roomId);
      refreshChatMessages(roomId);

      return () => {
        leaveRoom(roomId);
      };
    }
  }, [
    roomId,
    character,
    actualRoomId,
    refreshChatMessages,
    setHaveNewChatMessage,
  ]);

  useEffect(() => {
    if (haveNewChatMessage) {
      setHaveNewChatMessage(false);
    }
  }, [haveNewChatMessage, setHaveNewChatMessage]);

  const getRoomInfoName = (roomId) => {
    const roomInfo = getRoomInfo(roomId);
    return roomInfo ? roomInfo.name : "Main Page";
  };

  return (
    <div className="chat-message_page">
      <h2>Chat Room {getRoomInfoName(roomId)}</h2>
      <div className="chat-message_wrapper">
        {roomMessages.map((msg, index) => (
          <div key={index} className="chat-message">
            <div className="chat-message_avatar">
              <img
                src={msg.character.publicInfo.avatarImageLink}
                alt={msg.character.name}
              />
              <strong>{msg.character.name}: </strong>
            </div>
            <div className="chat-message_content">
              <div className="chat-message_timestamp">
                {new Date(msg.createdAt).toLocaleString()}
              </div>
              <p>{msg.text}</p>
            </div>
          </div>
        ))}
      </div>
      <ChatMessage roomId={roomId} />
    </div>
  );
};

export default ChatRoom;
