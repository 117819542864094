import React from "react";
import { AuthProvider } from "./AuthContext";
import { DataProvider } from "./DataContext";
import { GameProvider } from "./GameContext";

const Providers = ({ children }) => {
  return (
    <AuthProvider>
      <GameProvider>
        <DataProvider>
        {children}
        </DataProvider>
      </GameProvider>
    </AuthProvider>
  );
};

export default Providers;
