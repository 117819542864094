import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

const CharacterDetail = ({ characterId }) => {
  const [editEnabled, setEditEnabled] = useState(false);
  const { character } = useContext(AuthContext);

  useEffect(() => {
    setEditEnabled(characterId === character?.id);
  }, [characterId, character]);

  return (
    <div>
      <h2>{characterId}</h2>
      {editEnabled && <button>Modifica</button>}
    </div>
  );
};

export default CharacterDetail;
