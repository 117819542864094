import React, { useContext} from "react";
import { useParams } from "react-router-dom";
import { DataContext } from "../../context/DataContext";

const DocumentDetail = () => {
  const { slug } = useParams();
  const { documents } = useContext(DataContext);

  if (!documents) return <div>Loading...</div>;

  const document = documents.filter((doc) => doc.slug === slug);
  if (document.length === 0) {
    return <div>Document not found</div>;
  }

  return (
    <div>
      <h3>{document[0].title}</h3>
      <p>{document[0].content}</p>
      <div>{JSON.stringify(document[0],null,4)}</div>
    </div>
  );
};

export default DocumentDetail;
