import React, { createContext, useState, useEffect, useContext } from "react";
import { getChatRooms, getChatMessages } from "../services/chatService";
import { offChatMessage, onChatMessage } from "../services/socketService";
import { AuthContext } from "./AuthContext";

export const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const { isAuthenticated,isLoading } = useContext(AuthContext);
  const [activeSection, setActiveSection] = useState({ view: "mapList" });
  const [activeMap, setActiveMap] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [chatFlatList, setChatFlatList] = useState([]);
  const [roomMessages, setRoomMessages] = useState([]);
  const [haveNewChatMessage, setHaveNewChatMessage] = useState(false);

  const refreshChatMessages = async (roomId) => {
    getChatMessages(roomId).then((msgs) => {
      setRoomMessages(msgs);
      setHaveNewChatMessage(true);
    });
  };

  const getRoomInfo = (roomId) => {
    const roomInfo = chatFlatList.find((room) => room._id === roomId);
    return roomInfo || null;
  };

  useEffect(() => {
    console.log("activeMap changed", activeMap);
    if (activeMap) {
      refreshChatMessages(activeMap);
    } else {
      setRoomMessages([]);
    }
  }, [activeMap]);

  useEffect(() => {
    if (isAuthenticated) {
      getChatRooms().then((maps) => {
        setChatFlatList(maps);

        console.log("fetching chat rooms", maps);
        const mapById = {};
        maps.forEach((map) => (mapById[map._id] = { ...map, children: [] }));

        maps.forEach((map) => {
          if (map.children) {
            map.children.forEach((childId) => {
              if (mapById[childId]) {
                mapById[map._id].children.push(mapById[childId]);
              }
            });
          }
        });

        const rootMaps = maps.filter(
          (map) => !maps.some((m) => m.children.includes(map._id))
        );
        const treeMaps = rootMaps.map((rootMap) => mapById[rootMap._id]);
        console.log("fetching chat rooms", treeMaps);
        setChatList(treeMaps);
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      onChatMessage("GameContext", (msg) => {
        setRoomMessages((prevMessages) => [...prevMessages, msg]);
        setHaveNewChatMessage(true);
      });

      return () => {
        offChatMessage("GameContext");
      };
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const sectionToHash = (section) => {
      if (!section || !section.view) return '';
      let {view, ...rest} = section;
      Object.keys(rest).forEach(key => {
        view += `-${key}_${rest[key]}`;
      })
      return `${view}`;
    };
    
    if (activeSection) {
      const hash = sectionToHash(activeSection);
      window.location.hash = hash;
    }
  }, [activeSection]);

  useEffect(() => {
    const hashToSection = (hash) => {
      if (!hash) return {};
    
      const parts = hash.split('-');
      const view = parts.shift(); // Extract the view
      const rest = {};
    
      parts.forEach(part => {
        const [key, value] = part.split('_');
        rest[key] = value;
      });
    
      return { view, ...rest };
    };
    
    const handleHashChange = () => {
      const hash = window.location.hash.replace('#', '');
      if (hash) {
        const section = hashToSection(hash)
        setActiveSection(section);
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [setActiveSection]);


  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <GameContext.Provider
      value={{
        activeSection,
        setActiveSection,
        activeMap,
        setActiveMap,
        chatList,
        getRoomInfo,
        roomMessages,
        refreshChatMessages,
        haveNewChatMessage,
        setHaveNewChatMessage,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
