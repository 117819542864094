import api from "./api";

export const createChatRoom = async (name, description) => {
  try {
    const response = await api.post("/chatrooms", { name, description });
    return response.data;
  } catch (error) {
    console.error("Error creating chat room", error.message);
    return [];
  }
};

export const getChatRooms = async () => {
  try {
    const response = await api.get("/chatrooms");
    return response.data;
  } catch (error) {
    console.error("Error fetching chat rooms", error.message);
    return [];
  }
};

export const getChatRoom = async (id) => {
  try {
    const response = await api.get(`/chatrooms/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching chat room", error.message);
    return {};
  }
};

export const sendChatMessage = async (roomId, text) => {
  const response = await api.post("/chatmessages", { roomId, text });
  return response.data;
};

export const getChatMessages = async (roomId) => {
  const response = await api.get(`/chatmessages/${roomId}`);
  return response.data;
};

export const joinChatRoom = async (roomId) => {
  try {
    const response = await api.post(`/chatrooms/join`, { roomId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChatRoomVersion = async () => {
  const response = await api.get('/chatrooms/version/latest');
  return response.data;
};

