import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/DataContext";

const Weather = () => {
  const { weather: weatherData } = useContext(DataContext);
  const [time, setTime] = useState();

  const refreshTime = () => {
    const dateObject = new Date();
    const hour = dateObject.getHours();
    const minute = dateObject.getMinutes();
    const currentTime = `${hour < 10 ? "0" + hour : hour}:${
      minute < 10 ? "0" + minute : minute
    }`;
    setTime(currentTime);
  };

  useEffect(() => {
    setInterval(() => {
      refreshTime();
    }, 1000);
    refreshTime();
  }, []);

  return (
    <div className="weather_wrapper">
      <h3>{time}</h3>
      <p>{JSON.stringify(weatherData)}</p>
    </div>
  );
};

export default Weather;
