import React, { useState, useContext } from "react";
import { sendChatMessage } from "../../services/chatService";
import { AuthContext } from "../../context/AuthContext";

const ChatMessage = ({ roomId }) => {
  const [message, setMessage] = useState("");
  const { character } = useContext(AuthContext);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (message.trim() && character) {
      await sendChatMessage(roomId, message);
      setMessage("");
    }
  };

  return (
    <form onSubmit={handleSendMessage}>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
      />
      <button type="submit">Send</button>
    </form>
  );
};

export default ChatMessage;
