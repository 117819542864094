import React, { useState } from 'react';
import { forgotPassword } from '../../services/authService';

const ForgotPassword = ({onSendMail}) => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await forgotPassword(username);
      onSendMail('Password recovery email sent');
      setUsername('');
    } catch (err) {
      setError('Error sending password recovery email');
    }
  };

  return (
    <div className='forgotpassword_wrapper'>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder='Username'
            required
          />
        </div>
        {error && <p>{error}</p>}
        <button type="submit">Invia</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
