import api from './api';

export const createCharacter = async (name, characterClass) => {
  const response = await api.post('/characters', { name, class: characterClass });
  return response.data;
};

export const getCharacters = async () => {
  const response = await api.get('/characters');
  return response.data;
};

export const getCharacterOnline = async () => {
  const response = await api.get('/characters/online');
  return response.data;
};

export const getCharacter = async (id) => {
  const response = await api.get(`/characters/${id}`);
  return response.data;
};

export const updateCharacter = async (id, characterData) => {
  const response = await api.put(`/characters/${id}`, characterData);
  return response.data;
};

export const deleteCharacter = async (id) => {
  await api.delete(`/characters/${id}`);
};
