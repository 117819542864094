import React, { useContext, useState, useEffect, useRef } from "react";
import { DataContext } from "../../context/DataContext";
import "./FlashNews.css";

const FlashNews = () => {
  const [flashContent, setFlashContent] = useState(null);
  const { flashNews } = useContext(DataContext);
  const timerRef = useRef(null);

  const handleLeaveNews = () => {
    timerRef.current = setTimeout(() => {
      setFlashContent(null);
    }, 1000);
  };

  const handleEnterNews = (content) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setFlashContent(content);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <div className="flashnews_page">
      <div className="flashnews_wrapper">
        {flashNews.map((news, index) => (
          <div key={index} className="flashnews_item">
            <img
              src="https://s3.amazonaws.com/pix.iemoji.com/images/emoji/apple/ios-12/256/newspaper.png"
              alt="news"
              title={news.title}
              onMouseEnter={() => handleEnterNews(news.content)}
              onMouseLeave={handleLeaveNews}
            />
          </div>
        ))}
      </div>
      <div className="flashnews_footer">{flashContent}</div>
    </div>
  );
};

export default FlashNews;
