import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DocumentList from '../Document/DocumentList';
import DocumentDetail from '../Document/DocumentDetail';

const Document = () => {
  return (
    <div>
      <div>
        <h2>Documenti</h2>
        <Routes>
          <Route path="/" element={<DocumentList />} />
          <Route path=":slug" element={<DocumentDetail />} />
        </Routes>
      </div>
    </div>
  );
};

export default Document;
