import React, { createContext, useEffect, useState } from "react";
import {
  connectSocket,
  disconnectSocket,
  notifyLogout,
} from "../services/socketService";
import { verifyToken } from "../services/authService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [character, setCharacter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [socketLive, setSocketLive] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const response = await verifyToken();
      if (response?.user) {
        setIsAuthenticated(true);
        setUser(response.user);
        setCharacter(response.character);
        connectSocket(response.character.id);
        setSocketLive(true);
      } else {
        setIsAuthenticated(false);
        setUser(null);
        setCharacter(null);
        disconnectSocket();
        setSocketLive(false);
      }
      setIsLoading(false);
    };
    
    checkAuth();
    const interval = setInterval(checkAuth, 30000); // Verifica il token ogni 30 secondi
    return () => clearInterval(interval);
  }, []);

  const login = (data) => {
    setIsAuthenticated(true);
    setUser(data.user);
    setCharacter(data.character); 
    connectSocket(data.character.id);
  };

  const logout = () => {
    if (character) {
      setIsAuthenticated(false);
      setUser(null);
      setCharacter(null);
      connectSocket(character.id);
      notifyLogout(character.id);
      disconnectSocket();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        setUser,
        character,
        setCharacter,
        login,
        logout,
        socketLive,
        isLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
