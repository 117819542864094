import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Login from "../User/Login";
import Register from "../User/Register";
import ForgotPassword from "../User/ForgotPassword";
import { logout as logoutService } from "../../services/authService";
import "./Home.css";
import videoBackground from "../../assets/Home/home_video_desktop.mp4";
import RecoveryPassword from "./RecoveryPassword";

const Home = ({ externalView = 'login'}) => {
  const { isAuthenticated,isLoading, user, character, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [view, setView] = useState(externalView);
  const [showLogo, setShowLogo] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const handleEnter = () => {
    navigate("/game");
  };
  
  const handleGotoDocument = () => {
    navigate("/documents");
  };

  const handleLogout = async () => {
    await logoutService();
    logout();
  };

  const handleVideoEnd = () => {
    setShowLogo(true);
  };

  const handleTostMessage = (message) => {
    setToastMessage(message);
    setInterval(() => setToastMessage(null), 5000);
    setView("login");
  };

  useEffect(() => {
    console.log("HOME info", { user, character });
  }, [user, character]);

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="home_wrapper">
      <div className="home_header">
        <video autoPlay muted id="videoBackground" onEnded={handleVideoEnd}>
          <source src={videoBackground} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
        <div className={`home_logo ${showLogo ? "show" : ""}`}></div>
      </div>
      <div className="home_form">
        {isAuthenticated ? (
          <div className="form_wrapper">
            <div className="form_content_left">
              <div>Benvenuto, {user.username}</div>
            </div>
            <div className="form_content_right">
              <button onClick={handleEnter}>Entra</button>
              <button onClick={handleLogout}>Esci</button>
              <button onClick={handleGotoDocument}>Documenti</button>
            </div>
          </div>
        ) : (
          <>
            {view === "recoverypassword" && (
              <div className="form_wrapper popup">
                <div className="form_content_left">
                  <RecoveryPassword onSubmit={(message) => handleTostMessage(message)} />
                </div>
                <div className="form_content_right">
                  <button onClick={() => setView("login")}>Torna al Login</button>
                  <button onClick={handleGotoDocument}>Documenti</button>
                </div>
              </div>
            )}
            {view === "login" && (
              <div className="form_wrapper">
                <div className="form_content_left">
                  <Login />
                </div>
                <div className="form_content_right">
                  <button onClick={() => setView("register")}>
                    Crea Account
                  </button>
                  <button onClick={() => setView("forgotPassword")}>
                    Password Dimenticata
                  </button>
                  <button onClick={handleGotoDocument}>Documenti</button>
                </div>
              </div>
            )}
            {view === "register" && (
              <div className="form_wrapper popup">
                <div className="form_content_left">
                  <Register
                    onRegister={(message) => handleTostMessage(message)}
                  />
                </div>
                <div className="form_content_right">
                  <button onClick={() => setView("login")}>
                    Torna al Login
                  </button>
                  <button onClick={handleGotoDocument}>Documenti</button>
                </div>
              </div>
            )}
            {view === "forgotPassword" && (
              <div className="form_wrapper popup">
                <div className="form_content_left">
                  <ForgotPassword
                    onSendMail={(message) => handleTostMessage(message)}
                  />
                </div>
                <div className="form_content_right">
                  <button onClick={() => setView("login")}>
                    Torna al Login
                  </button>
                  <button onClick={handleGotoDocument}>Documenti</button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={`toastmessage ${toastMessage ? "show" : ""}`}
        onClick={() => setToastMessage(null)}
      >
        {toastMessage}
      </div>
    </div>
  );
};

export default Home;
